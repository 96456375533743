import create from "zustand";

interface IStoreState {
  selectedNurse: string;
}

interface IStoreActions {
  setSelectedNurse: (selectedNurse: string) => void;
}

interface IStore extends IStoreState, IStoreActions {}

const initialState: IStoreState = {
  selectedNurse: null,
};

const useHTManagementState = create<IStore>((set, get) => ({
  ...initialState,

  setSelectedNurse: (selectedNurse: string) => set({ selectedNurse: selectedNurse }),
  resetStore: () =>
    set({
      ...initialState,
    }),
}));

export default useHTManagementState;
