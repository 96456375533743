import React from "react";

import { Avatar, Chip, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import styled from "styled-components";

import useHTManagementState from "~/stores/HTManagementStore";

const CardImage = styled(CardMedia)`
  height: 200px;
`;

interface Props {
  names: string;
  id: string;
  picture: string;
  variant: string;
  services_category: Array<string>;
  selectedNurseId: string | null;
}

const NurseCard = (props: Props): JSX.Element => {
  const { setSelectedNurse } = useHTManagementState();

  return props.variant == "small" ? (
    <ListItem
      selected={props.selectedNurseId == props.id}
      onClick={() => setSelectedNurse(props.id)}
      dense
    >
      <ListItemAvatar>
        <Avatar
          alt=""
          src={props.picture}
        />
      </ListItemAvatar>
      <ListItemText
        primary={props.names}
        secondary={
          <React.Fragment>
            {props.services_category?.map((item) => (
              <Chip
                size="small"
                label={item}
                key={item}
              />
            ))}
          </React.Fragment>
        }
      ></ListItemText>
    </ListItem>
  ) : (
    <Card
      variant="outlined"
      onClick={() => {
        navigate(`/health-team/${props.id}/`);
      }}
    >
      <CardActionArea>
        <CardImage image={props.picture} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="h3"
          >
            {props.names}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NurseCard;
